#linking-accounts {
  padding-bottom: 200px;
  text-align: left;
  position: relative;
}

#linking-accounts .popup-linking-account {
  left: 0px;
  top: 20px !important;
}
.linking-accounts-content {
  background-color: transparent;
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  border: 1px solid #eeeeee2b;
  margin: 20px auto;
  font-family: Arial, sans-serif;
  text-align: left;
}

.social-login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.list-linking-account {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-linking-account img {
  width: 50px;
  height: 50px;
}
@media (max-width: 600px) {
  .linking-accounts-content {
    width: 90%;
  }
  #linking-accounts {
    padding-inline: 20px;
  }

  .list-linking-account {
    flex-direction: column;
  }
}
