.content-left-menu {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.left-menu-logo {
  width: 150px;
  margin-bottom: 30px;
}
.section-item-menu {
  text-align: left;
}

.section-item-menu span {
  opacity: 0.5;
  text-align: left;
  font-size: 0.9rem;
}

.section-item-menu ul {
  text-align: left;
  list-style: none;
  color: white;
  opacity: 0.9;
  padding: 0;
}

.section-item-menu ul li {
  margin-block: 30px;
}

.section-item-menu ul li a {
  color: white;
}

.section-item-menu ul li i {
  margin-right: 20px;
}

.left-menu-item.active a {
  color: var(--primary-color);
}
.logout {
  margin: 0 auto;
  background-color: transparent;
  border: 0;
  color: white;
  cursor: pointer;
}

.logout i {
  margin-top: 10px;
  font-size: 30px;
}
