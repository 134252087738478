.bottom-navigation-content {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 80%;
}

.bottom-navigation {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #1d2127;
  padding: 10px 0;
  border-top: 2px solid #333;

  max-width: 100%;
  margin: 0 auto;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.nav-item span {
  margin-top: 4px;
  font-size: 12px;
}

.activaitemnav,
.activaitemnav span {
  color: var(--primary-color);
}

@media (max-width: 1200px) {
  .bottom-navigation-content {
    width: 75%;
  }
}

@media (max-width: 890px) {
  .bottom-navigation-content {
    width: 65%;
  }
}

@media (max-width: 670px) {
  .bottom-navigation-content {
    width: 100%;
  }
}
