.content {
  padding-inline: 20px;
}

.text-center {
  text-align: center !important;
}
.flex-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.aling-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.launch-container {
  height: 100vh;
}

.social-network {
  width: 220px;
  font-size: 30px;
  color: white;
  justify-content: space-between;
}
.social-network-footer {
  display: flex;
  width: 220px;
  font-size: 30px;
  color: white;
  justify-content: space-between;
}
.launch-container p {
  width: 80%;
  text-align: center;
  font-size: 30px;
}
.logoLaunch {
  width: 400px;
}

.launch-btns {
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.launch-btns div {
  width: 25%;
  margin-inline: 40px;
}

.launch-btns button {
  height: 70px;
}

.line-container {
  position: absolute;
  width: 100%;
  height: 100vh;
}

.line-horizontal,
.line-vertical {
  background-color: #00f0ff;
  box-shadow: 0 0 10px #00f0ff, 0 0 20px #00f0ff, 0 0 30px #00f0ff,
    0 0 40px #00f0ff, 0 0 50px #00f0ff;
  animation: neon-glow 2s infinite alternate;
}

.line-horizontal {
  position: absolute;
  top: 40%;
  right: 0px;
  width: 80px;
  height: 2px;
}

.line-horizontal.secondary {
  position: absolute;
  top: 40%;
  right: 100px;
  width: 20px;
  height: 2px;
}

.line-vertical {
  position: absolute;
  bottom: 0%;
  left: 50%;
  width: 2px;
  height: 40px;
  transform: translateX(-50%);
}

nav {
  padding-bottom: 20px;
  border-bottom: 1px solid #968d8d38;
}
nav .content-nav {
  display: flex;
  align-items: center;
}
nav .content-nav ul {
  display: flex;
  list-style: none;
  gap: 40px;
}
nav .content-nav ul li a {
  color: white;
  font-weight: bold;
}

#usability {
  display: flex;
  margin-top: 2%;

  justify-content: center;
}
#usability div {
  width: 33.33%;
  display: flex;
  justify-content: center;
  text-align: center;
}

#usability .item-usability {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 20%;
}

.item-usability.left {
  align-items: flex-end;
}
.item-usability.right {
  align-items: flex-start;
}

#about {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

#about h1 {
  margin-bottom: 50px;
}

.card-home {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #404040;
  border-radius: 50px;
}
.card-home p {
  width: 100%;
  text-align: left;
  font-size: 22px;
  line-height: 30px;
}

#roadmap ul {
  font-size: 20px;
  color: white;
  font-weight: bold;
  line-height: 50px;
}

#roadmap ul li::marker {
  font-size: 40px;
}

#roadmap ul.active li::marker {
  color: #2e75b6;
}

.laptop-content-img img {
  width: 100%;
}

#content-access div {
  justify-content: center;
  display: flex;
  gap: 20px;
}

#content-access div img {
  width: 10%;
}

.faq-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.faq-item {
  background-color: #404040;
  color: #fff;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 15px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  width: 100%;
}

.faq-answer-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.faq-answer-container.open {
  max-height: 300px;
  padding: 10px 20px;
}

.faq-answer {
  font-size: 14px;
  line-height: 1.5;
}

.arrow {
  font-size: 12px;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

#start-you-crypto .card-home .flex-row div.item-start-your {
  width: 50%;
}

.logofooter {
  width: 50%;
}

#footer-btns div div {
  background-color: #3f88f8;
  width: 45%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
}

#content-footer-logo {
  width: 50%;
}

#form-contact {
  width: 60%;
  box-sizing: border-box;
}

#form-contact input,
#form-contact textarea {
  height: 43px;
  background: transparent;
  border: 1px solid #ffffff78;
  border-radius: 10px;
  box-sizing: border-box;
}

#form-contact textarea {
  width: 100%;
  height: 200px;
  border-radius: 38px;
  color: white;
  padding: 20px;
}

#btn-submit {
  background-color: #3f88f8;
  width: 35%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

#content-submit {
  display: flex;
  justify-content: flex-end;
}
.item-telegram {
  padding-block: 3%;
  width: 20%;
  border-radius: 20px;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.318);
  color: white;
  cursor: pointer;
  box-sizing: border-box;
}

.item-telegram .icontelegram {
  background-color: white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin: 0 auto;
  color: #282c34;
}

.hours {
  width: 50%;
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hours h3 {
  font-size: 23px;
  font-weight: bold;
}
.hours p {
  font-size: 20px;
  font-weight: lighter;
}
@keyframes neon-glow {
  0% {
    box-shadow: 0 0 5px #00f0ff, 0 0 10px #00f0ff, 0 0 15px #00f0ff,
      0 0 20px #00f0ff;
  }
  50% {
    box-shadow: 0 0 10px #00f0ff, 0 0 20px #00f0ff, 0 0 30px #00f0ff,
      0 0 40px #00f0ff;
  }
  100% {
    box-shadow: 0 0 5px #00f0ff, 0 0 10px #00f0ff, 0 0 15px #00f0ff,
      0 0 20px #00f0ff;
  }
}

@media (max-width: 1277px) {
  .launch-btns div {
    width: 35%;
  }
}

@media (max-width: 915px) {
  .launch-btns div {
    width: 45%;
  }
  .item-telegram,
  #form-contact {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media (max-width: 780px) {
  .flex-row {
    flex-direction: column;
  }
  #usability {
    margin-top: 60px;
    flex-direction: column;
    align-items: center;
  }

  .item-usability.left,
  .item-usability.right {
    align-items: center;
    width: 80% !important;
  }

  #usability .item-usability {
    width: 45% !important;
  }

  #usability .item-usability .item {
    margin-bottom: 40px;
    width: 70%;
  }
  #start-you-crypto .card-home .flex-row div.item-start-your,
  #start-you-crypto .card-home .flex-row div.item-start-your img {
    width: 100% !important;
  }

  #content-footer-logo,
  #footer-btns {
    width: 100%;
  }
}
@media (max-width: 600px) {
  nav ul {
    display: none !important;
  }

  .logoLaunch {
    width: 200px;
  }

  .line-horizontal {
    width: 65px;
  }

  .line-horizontal.secondary {
    right: 80px;
  }

  .launch-btns div {
    margin-inline: 15px;
  }
}

@media (max-width: 400px) {
  .logoLaunch {
    width: 200px;
  }

  .line-horizontal {
    width: 55px;
  }

  .line-horizontal.secondary {
    right: 60px;
  }
}
