#support-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

#support-items .item {
  background-color: #ffffff0d;
  width: 25%;
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

#support .faq-item {
  background-color: #ffffff0d;
}

#support .faq-answer-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

#support .faq-answer-container.open {
  max-height: 300px;
}

#support .faq-answer-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

#support .faq-answer-container.open {
  max-height: 300px;
}

#support .faq-question {
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#support .arrow {
  transition: transform 0.3s ease;
}

#support .arrow.open {
  transform: rotate(180deg);
}

#support .faq-answer {
  padding: 10px 0;
}
