#settings {
  padding-inline: 20px;
}
#settings .item-settings {
  background-color: #ffffff0d;
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding-inline: 20px;
  box-sizing: border-box;
}

#settings .item-settings .value {
  color: #3f88f8;
}
