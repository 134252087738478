iframe {
  width: 100%;
  height: 70vh;
  border: 0;
  margin-top: 40px;
}

#airdrop {
  padding-inline: 20px;
  padding-bottom: 200px;
}
#airdrop .info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

#airdrop .balance-amount {
  font-weight: bold;
}
#airdrop span {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
}

#airdrop .content-poster {
  position: relative;
}
#airdrop .content-poster img {
  width: 100%;
  border-radius: 20px;
}

#airdrop button {
  width: 100px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 20px;
}

.closegame {
  position: absolute;

  right: 20px;
}

#airdrop .item-info {
  cursor: pointer;
  position: relative;
  background-color: #ffffff14;
  padding-inline: 10px;
  width: 43%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
#airdrop .item-info span {
  font-size: 15px;
  line-height: 20px;
  font-weight: normal;
}

#airdrop .item-info i {
  color: white;
  font-size: 30px;
  position: absolute;
  top: -10px;
}
