.content-telegram {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.content-telegram #telegram-login {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 9999;
  top: -35px;
  left: 6px;
}
