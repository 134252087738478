.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tab-headers {
  display: flex;
  color: #fff;
}

.tab-header {
  padding: 10px 10px;
  cursor: pointer;
  position: relative;
}

.tab-header.active {
  color: #fff;
  background-color: transparent;
  font-weight: bold;
  transition: all 0.3s ease, left 0.3s ease;
}

.tab-content {
  padding-bottom: 20px;
  border-top: none;
}

.tab-header::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -2px;
  height: 2px;
  width: 0;
  background-color: #007bff;
  transition: width 0.3s ease, left 0.3s ease;
  transform: translateX(-50%);
}

.tab-header.active::after {
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
}
