input {
  background-color: #827f7f17;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding-left: 20px;
  width: 100%;
}

input.input-xs {
  height: 35px;
}

input.input-md {
  /*padding: 20px 0px; */
  height: 50px;
}

.label-input {
  font-size: 20px;
  display: block;
  margin-bottom: 10px;
  color: #ccc;
}

.content-input {
  width: 100%;
  position: relative;
  display: flex;
}

.content-input.disabled {
  opacity: 0.5;
}
.span-action {
  position: absolute;
  right: 20px;
  top: 34%;
  cursor: pointer;
}

@media (max-width: 600px) {
}
