.task-list {
  align-items: center;
  color: #fff;
  width: 100%;
  border-radius: 10px;
  justify-content: space-around;
  margin: 0px auto 0px;
  padding-bottom: 100px;
}

.task-header {
  text-align: center;
  margin-bottom: 20px;
}

.task-title {
  font-size: 20px;
  color: var(--text-color);
  width: 136px;
  margin: 0 auto;
  background-color: var(--background-panel);
  padding: 11px 20px;
  border-radius: 9px;
}

.task-description {
  font-size: 16px;
  margin: 10px 0 0 0;
}

.task-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  padding: 8px;
  background-color: var(--background-panel);
  border-radius: 5px;
}

.task-icon {
  font-size: 20px;
  margin-right: 10px;
  width: 37%;
  text-align: left;
}

.task-content-image {
  width: 38%;
  text-align: left;
}
.task-image {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  text-align: left;
}
.task-platform {
  flex-grow: 1;
  font-size: 14px;
  text-align: left;
}

.task-bonus {
  font-size: 14px;
  margin-right: 10px;
}

.task-items #telegram-login {
  width: 80%;
}

.task-footer {
  text-align: center;
  display: flex;
  justify-content: center;
}
.task-footer center {
  width: 50%;
  margin: 0 auto;
}

.activate-bonus {
  font-size: 20px;
  margin-bottom: 10px;
}

.activate-button {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  width: 100px;
}

.popup-linking-account {
  display: flex;
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 70%;
  top: 0;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.popup-linking-account .content-popup {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  position: absolute;
  text-align: center;
  background-color: var(--background-color);
  border: 1px solid #eeeeee3f;
  height: 150px;
  width: 400px;
  padding: 20px;
  color: white;
  border-radius: 20px;
  font-size: 20px;
}

.activateReward {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border-width: 2px;
  border-color: #f8f8f84d;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activateReward.disabled {
  opacity: 0.5;
}

@media (max-width: 768px) {
  .popup-linking-account .button-lg {
    padding: 0.875em 1.75em;
    font-size: 1rem;
    width: 310px;
  }
}

@media (max-width: 600px) {
  .task-icon {
    width: 27%;
  }
  .task-content-image {
    width: 29%;
  }
}

@media (max-width: 500px) {
  .task-icon {
    width: 10%;
  }

  .task-content-image {
    width: 13%;
  }

  .popup-linking-account .content-popup {
    width: 85%;
  }
}

@media (max-width: 360px) {
  .popup-linking-account .content-popup {
    width: 85%;
  }

  .popup-linking-account .button-lg {
    padding: 0.875em 1.75em;
    font-size: 1rem;
    width: 90%;
  }
}
