.button {
  position: relative;
  overflow: hidden;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 5px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.button .label {
  font-size: inherit;
  font-weight: bold;
  position: relative;
  width: 100%;
}

.button .label .icon-right {
  position: absolute;
  right: 4px;
  top: -2px;
}

.button .label .icon-left {
  position: absolute;
  left: 4px;
  top: -2px;
}

.button i {
  font-size: 1.25rem;
  margin-inline: 5px;
}

.button-xs {
  font-size: 0.875rem;
  height: 20px;
}

.button-md {
  padding: 0.75em 1.5em;
  font-size: 1rem;
  height: 30px;
}

.button-lg {
  font-size: 1.125rem;
  height: 40px;
  width: 100%;
}

.button-disabled {
  opacity: 0.5;
}

@keyframes ripple-animation {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

/* Media Queries */
@media (max-width: 768px) {
  .button {
    font-size: 0.875rem;
  }

  .button-xs {
    font-size: 0.75rem;
  }

  .button-md {
    font-size: 0.875rem;
  }

  .button-lg {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .button {
    font-size: 0.75rem;
  }

  .button-xs {
    font-size: 0.75rem;
  }

  .button-md {
    font-size: 0.75rem;
  }

  .button-lg {
    font-size: 0.875rem;
  }
}
