#trade {
  padding-bottom: 300px;
}

#trade .head-container {
  color: white;
  padding: 10px 0px;
  text-align: center;
}

.head-container .section {
  margin-bottom: 20px;
}

#trade .head-label {
  font-size: 20px;
  color: #fff;
}

#trade .section i {
  margin-left: 10px;
  font-size: 22px;
  cursor: pointer;
  color: #fff;
}

#trade .scroll-item {
  background-color: #ffffff12;
  height: 50px;
  border: 0;
}

#trade .scroll-item .label {
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#trade .scroll-item .label i {
  font-size: 10px;
}

#trade .scroll-item .amount {
  font-size: 19px;
}

#trade .amount-section span {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

#trade .amount-section small {
  font-size: 17px;
  color: var(--primary-color);
}

.chart {
  border: 1px solid #ffffff29;
  border-radius: 35px;
}

.chart .tolbar {
  width: 70%;
  margin: 0 auto;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.tolbar span {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.tolbar .custom-icon i {
  color: white;
  margin-left: 7px;
}

#trade .actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
}

#trade .actions .item-action {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid #ffffff29;
  border-radius: 10px;
  padding-block: 10px;
  width: 100px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  padding-inline: 5px;
  cursor: pointer;
}

.open-orders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ffffff29;
  padding-inline: 20px;
  cursor: pointer;
  border-radius: 10px;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.open-orders p {
  margin-block: 13px;
}

#trade .content-market {
  position: relative;
  border: 1px solid #ffffff29;
  border-radius: 35px;
  padding-block: 20px;
}

#trade .content-market .close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
}

#trade .content-market .form {
  text-align: left;
  padding-inline: 20px;
}

#trade .content-market .form .inputs-orders {
  display: flex;
  justify-content: space-between;
}

#trade .content-market .form .inputs-orders .input-group {
  width: 45%;
}

#trade .content-market .form label {
  font-size: 16px;
  color: white;
}
#trade .content-market .form .leverage {
  margin-top: 10px;
  background-color: #827f7f17;
  padding-inline: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-radius: 9px;
  font-size: 20px;
  font-weight: bold;
}

#trade .btn-execute {
  width: 60%;
  margin: 0 auto;
}
#trade .content-market .form .leverage i {
  cursor: pointer;
}

#trade .scroll-item {
  width: 26.5%;
}

@media (max-width: 600px) {
}

@media (max-width: 390px) {
  #trade .scroll-item {
    width: 26%;
  }
}

@media (max-width: 360px) {
  #trade .scroll-item {
    width: 25%;
    font-size: 11px;
  }
  #trade .scroll-item .amount {
    font-size: 12px;
  }
}
