#our-team {
  display: flex;
  gap: 5%;
}

#team-lead {
  width: 40%;
}

#team-chief {
  width: 60%;
}

#photo-lead {
  width: 50%;
  margin: 0 auto;
  background-color: white;
  overflow: hidden;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#photo-lead img {
  width: 100%;
  object-fit: contain;
}

.icontelegram {
  background-color: white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin: 0 auto;
  color: #282c34;
}

/* Contenedor principal */
.employee-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #fff;
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Fila de empleado */
.employee-row {
  display: flex;
  justify-content: space-between; /* Espaciado entre columnas */
  align-items: center; /* Centrar verticalmente */
  width: 80%;
  padding: 10px 0;
  text-align: center;
  box-sizing: border-box;
}

/* Columna: Nombre del empleado */
.employee-name {
  flex: 2;
  text-align: center; /* Centrar texto */
}

/* Columna: Rol del empleado */
.employee-role {
  flex: 3;
  text-align: center; /* Centrar texto */
}

/* Columna: Enlace de contacto */
.employee-contact {
  flex: 1;
  color: #fff;
  text-decoration: underline;
  font-size: 16px;
  cursor: pointer;
  text-align: center; /* Centrar texto */
}

.employee-contact:hover {
  color: #ddd;
}

#employes {
  padding-inline: 20% !important;
}

@media (max-width: 780px) {
  #our-team {
    flex-direction: column;
    width: 100%;
  }
  #team-lead,
  #team-chief {
    width: 100%;
  }

  #employes {
    padding-inline: 3% !important;
  }

  #team-chief .card-home {
    margin-bottom: 20px;
  }

  .employee-row {
    flex-direction: column;
    justify-content: center; /* Centrar todos los elementos */
    align-items: center; /* Centrar verticalmente */
    width: 100%; /* Ajustar al ancho completo */
    padding: 15px 0;
    border-bottom: 1px solid #555;
  }

  /* Ajustar el espaciado */
  .employee-name,
  .employee-role,
  .employee-contact {
    flex: none; /* Eliminar el flex */
    text-align: center; /* Centrar texto */
    margin-bottom: 5px; /* Añadir espacio entre líneas */
  }

  /* Cambiar tamaño de texto */
  .employee-name {
    font-size: 18px; /* Más grande en móviles */
  }

  .employee-role {
    font-size: 16px;
    color: #bbb; /* Más suave para diferenciar */
  }

  .employee-contact {
    font-size: 18px;
  }
}
