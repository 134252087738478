.splashContainer{
    width: 100%;
    height: 100vh;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoSplash{
    width: 300px;
}
