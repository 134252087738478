#stake-summary {
  padding-bottom: 200px;
}

#stake-summary .btn-cancel {
  width: 60%;
  margin: 0 auto;
}
.scroll-container {
  display: flex;
  overflow-x: auto;
  padding: 10px;
  padding-bottom: 30px;
}

.scroll-item {
  flex: 0 0 auto;
  margin-right: 10px;
  width: 150px;
  height: 60px;
  padding: 10px;
  background-color: var(--background-panel);
  color: #0f0;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #ffffff2b;
  text-align: left;
  cursor: pointer;
}

.scroll-item.active {
  border: 1px solid #ffffff75;
}

.scroll-item div:first-child {
  font-size: 20x;
  color: #ccc;
  margin-bottom: 10px;
}

.scroll-item div:last-child {
  font-size: 23px;
  color: var(--primary-color);
}

.transaction-form {
  padding: 20px;
  color: #ccc;
  margin: auto;
  text-align: left;
  border-bottom: 1px solid #eee3;
  padding-bottom: 25px;
}

.input-group {
  width: 100%;
  margin-bottom: 15px;
}

.max-button {
  padding: 10px 15px;
  background-color: #333;
  border: 1px solid #333;
  border-radius: 4px;
  margin-left: 10px;
  color: #ccc;
  cursor: pointer;
}

.max-button:hover {
  background-color: #555;
}

.details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 12px;
}

.operation-limit {
  margin-bottom: 20px;
  font-size: 12px;
}

.transaction-form button {
  margin: 0 auto;
}

.border {
  border-bottom: 1px solid #000;
}

.stake-info {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  background-color: #ffffff0d;
  border-radius: 14px;
}

.stake-info-item {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
}

.stake-info-label {
  display: flex;
  align-items: center;
  color: #fff;
}

.stake-info-icon {
  color: var(--primary-color);
  margin-right: 10px;
}

.stake-info-value {
  color: #ffffff;
}

#content-tabs-stake {
  position: relative;
  max-width: 95%;
  margin: 0 auto;
}

.text-support-stake i {
  font-size: 25px !important;
  color: #fff;
  display: none;
}

@media (max-width: 600px) {
  .scroll-container {
    justify-content: flex-start;
  }

  .scroll-item {
    width: 120px;
    height: 55px;
  }

  .text-support-stake i {
    display: block;
  }
  .stake-info {
    width: 90%;
  }
}

@media (max-width: 390px) {
  .stake-info-item {
    padding: 12px 15px;
  }
  .stake-info {
    width: 100%;
  }
}
