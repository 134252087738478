#withdraw {
  padding-bottom: 200px;
}

#withdraw .dropdown-deposit {
  background-color: #827f7f17;
  width: 400px;
  height: 50px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-inline: 20px;
  position: relative;
  cursor: pointer;
}
#withdraw .content-transaction {
  padding-inline: 20px;
}

.dropdown-deposit i {
  color: white;
  font-size: 20px;
}

.options-transfer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;
  margin-block: 10px;
}

.options-transfer-item i {
  font-size: 20px;
}

.options-transfer-item {
  position: relative;
  cursor: pointer;
}

#withdraw .dropdown-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  z-index: 100;
  width: 200px;
  width: 400px;
  bottom: -50px;
}

.dropdown-item {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  color: black;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

#withdraw .btn-copy {
  width: 70%;
  justify-content: space-between;
  padding-inline: 20px;
  overflow: hidden;
  cursor: pointer;
}

#withdraw .btn-copy i {
  color: white;
}

#withdraw .wallet-address {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 600px) {
  #withdraw .dropdown-deposit {
    width: 80%;
  }
  #withdraw .dropdown-menu {
    width: 100%;
    left: 0px;
  }
}
