#content-home {
  position: relative;
  padding-bottom: 50px;
  padding-inline: 5px;
}
.arrow {
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  top: 55px;
  z-index: 999;
}

.arrow i {
  color: var(--primary-color);
}

.left-arrow {
  position: absolute;
  left: 28px;
}

.right-arrow {
  position: absolute;
  right: 28px;
}

#content-home .tab-headers {
  margin-top: 20px;
}
@media (max-width: 600px) {
  #content-home {
    padding-inline: 20px;
  }
}
