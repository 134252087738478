#frens {
  padding-bottom: 200px;
  text-align: left;
}

#frens button {
  font-size: 13px;
}

.wallet-transfer {
  background-color: transparent;
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;

  margin: 20px auto;
  font-family: Arial, sans-serif;
  text-align: left;
}

.wallet-balance {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.btn-exchange,
.btn-claim {
  margin: 0 auto;
  width: 30%;
}

.usdt-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.balance-amount {
  font-size: 1.5em;
}

.transfer-text {
  margin: 10px 0;
}

.transfer-input {
  background-color: #333;
  color: #aaa;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
}

.transfer-button {
  background-color: #00a86b;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.transfer-button:hover {
  background-color: #007f50;
}

.transfer-icon {
  font-size: 1.2em;
}

.user-list-container {
  color: white;
  width: 100%;
  height: 250px;
  max-height: 250px;
  margin: 0 auto;
  overflow: scroll;
  overflow-x: hidden;
  background-color: #ffffff1a;
  border-radius: 14px;
}
.user-row span {
  width: 50%;
}

.user-list-container::-webkit-scrollbar {
  width: 10px;
}

.user-list-container::-webkit-scrollbar-track {
  background: #333333;
}

.user-list-container::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #2a2a2a;
}

.user-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.user-name,
.user-balance {
  font-size: 14px;
}

.user-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.claim-button {
  background-color: #00a8a8;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
}

.claim-button:hover {
  background-color: #008a8a;
}

.content-icon-copy {
  position: absolute;
  right: 20px;
  bottom: 16px;
  color: #fee;
  font-size: 20px;
  cursor: pointer;
}

.btn-copy {
  width: 100%;
  margin: 0px auto;
  position: relative;
  background: #3e4249;

  height: 45px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 6px;
}
.btn-copy p {
  font-weight: bold;
}

#frens .user-list-container .button {
  width: 65px;
}
@media (max-width: 600px) {
  #frens {
    padding-inline: 20px;
  }
}
