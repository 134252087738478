.contentHeader {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.contentHeader div {
  background-color: #1d2127;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.contentHeader div i {
  color: white;
  font-size: 50px;
}
.logo {
  width: 117px;
  position: absolute;
  top: 23px;
  left: 18px;
}

.avatarImage {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .logo {
    display: none;
  }
}

@media (max-width: 670px) {
  .icon-bars-menu {
    display: block;
  }
}
