#wallet {
  padding-bottom: 200px;
}

.balance-container {
  color: white;
  padding: 10px 0px;
  text-align: center;
}

.balance-section {
  margin-bottom: 20px;
}

.balance-section i {
  color: #fff;
  font-size: 20px;
}
.balance-label {
  font-size: 24px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}

.info-icon {
  margin-left: 10px;
  font-size: 12px;
  cursor: pointer;
  color: #ffffff8f;
}

.balance-amount {
  margin-top: 5px;
  font-size: 16px;
}

.wallet-actions {
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
  padding-inline: 200px;
}

.wallet-actions .item {
  background-color: #ffffff14;

  border-radius: 14px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  width: 27%;
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wallet-actions .item i {
  font-size: 25px;
  margin-block: 3px;
}

.wallet-actions .item span {
  margin-block: 3px;
}

.transactions {
  text-align: left;
  padding-inline: 0px;
}

.items-transactions {
  height: 400px;
  overflow: scroll;
}
.transactions .items-transactions {
  background-color: #ffffff14;
  border-radius: 20px;
}
.item-deposit {
  width: 100%;
}

.listItem {
  display: flex;
  flex-direction: row;

  padding: 20px;
  justify-content: space-between;
  margin-bottom: 15px;
  color: #fff;
}

.listItem.head {
  background-color: transparent;
  color: var(--primary-color);
}

.content-buttons-wallet {
  display: flex;
  justify-content: space-between;
  padding-inline: 30px;
}

.deposit-only {
  font-size: 15px;
}

.transaction-details {
  background-color: var(--background-panel);
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  min-height: 266px;
  margin: 0 auto;
  width: 95%;
}

.transaction-amount {
  font-weight: bold;
}

.transaction-address {
  word-break: break-all;
}

.transaction-dates {
  font-size: 0.9em;
  opacity: 0.8;
}

.text-support {
  font-size: 12px;
  display: none;
}
.text-support i {
  color: #fff;
}

@media (max-width: 1500px) {
  .wallet-actions {
    padding-inline: 100px;
  }
}

@media (max-width: 820px) {
  .wallet-actions {
    padding-inline: 50px;
  }
}

@media (max-width: 600px) {
  #wallet {
    padding-inline: 20px;
  }
  .listItem {
    font-size: 14px;
  }

  .transaction-details {
    font-size: 14px;
  }

  .text-support {
    display: block;
  }
}

@media (max-width: 460px) {
  .wallet-actions {
    padding-inline: 20px;
  }

  .listItem {
    padding: 10px;
  }
}

@media (max-width: 430px) {
  .wallet-actions .item {
    width: 100px !important;
  }
  .item-deposit {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .wallet-actions {
    padding-inline: 2px;
  }
}

@media (max-width: 390px) {
}

@media (max-width: 360px) {
  .item-deposit {
    width: 100%;
  }

  .item-deposit .listItem {
    font-size: 14px;
  }
}

@media (max-width: 370px) {
  .wallet-actions {
    padding-inline: 1px;
  }

  .wallet-actions .item {
    width: 26% !important;
    font-size: 20px;
  }

  .wallet-actions .item i {
    font-size: 20px;
  }

  .wallet-actions .item span {
    font-size: 15px;
  }
}
