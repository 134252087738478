#transfer {
  padding-bottom: 200px;
}

#transfer .details {
  justify-content: flex-end;
}

.transaction-form {
  border: none;
}

.content-transaction {
  padding-inline: 20px;
}
.content-options-transfer {
  background-color: #827f7f17;
  display: flex;
  justify-content: space-between;
  padding-inline: 40px;
  padding-block: 20px;
  align-items: center;
  margin: 0 auto;
  color: white;
  border-radius: 15px;
}
.content-options-transfer i {
  font-size: 30px;
}

.options-transfer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;
  margin-block: 10px;
}

.options-transfer-item i {
  font-size: 20px;
}

.options-transfer-item {
  position: relative;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  z-index: 100;
  width: 200px;
}

.dropdown-item {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  color: black;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
