/* src/components/LoginPage.css */

.login-container {
  display: flex;
  height: 100vh;
}
.login-container a {
  color: var(--primary-color);
}

.login-left,
.login-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-left {
  padding: 2rem;
}

.login-right {
  background-image: url('https://i.pinimg.com/736x/d9/39/30/d939308804ffadb248a90c15518e360f.jpg');
  background-color: var(--background-color);
  color: #fff;
  padding: 2rem;
}

.login-content {
  max-width: 400px;
  width: 100%;
  text-align: center;
}

h1 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

p {
  margin-bottom: 1rem;
  color: #6c757d;
}

.social-login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
}

form {
  display: flex;
  flex-direction: column;
}

input[type='email'],
input[type='password'] {
  padding: 0.7rem;
  margin-bottom: 1rem;
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.login-button {
  background-color: var(--primary-color);
  color: #fff;
  padding: 0.7rem;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.promo-content {
  text-align: center;
}
.promo-content p {
  color: #fff;
}

.promo-content img {
  width: 200px;
  margin-bottom: 1rem;
}

.loadingAuth {
  position: absolute;
  background: #fdfdfdc2;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .login-content {
    padding-inline: 12%;
  }

  .login-content .logo {
    margin-bottom: 10px;
  }
  .btn-provider {
    margin: 0;
  }

  .login-left,
  .login-right {
    padding: 0px;
    flex: none;
    width: 100%;
  }

  .login-left {
    order: 1;
    height: 100vh;
  }

  .login-right {
    display: none;
  }

  .promo-content img {
    width: 150px;
  }

  .social-login {
    flex-direction: column;
  }

  .google-login,
  .facebook-login {
    margin: 0.5rem 0;
  }

  .form-options {
    flex-direction: column;
    align-items: flex-start;
  }

  .form-options label {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 600px) {
  .login-container .logo {
    display: block !important;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.2rem;
  }

  .promo-content img {
    width: 120px;
  }
}
