#stake {
  padding-bottom: 300px;
}
.scroll-container {
  display: flex;
  overflow-x: auto;
  padding: 10px;
  padding-bottom: 30px;
}

.scroll-item {
  flex: 0 0 auto;
  margin-right: 10px;
  width: 150px;
  height: 60px;
  padding: 10px;
  background-color: var(--background-panel);
  color: #0f0;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #ffffff2b;
  text-align: left;
  cursor: pointer;
}

.scroll-item.active {
  border: 1px solid #ffffff75;
}

.scroll-item div:first-child {
  font-size: 20x;
  color: #ccc;
  margin-bottom: 10px;
}

.scroll-item div:last-child span {
  font-size: 23px;
  color: var(--primary-color);
}

.content-daily {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.scroll-item div:last-child span.daily {
  font-size: 14px;
  color: white;
}

.transaction-form {
  padding: 20px;
  color: #ccc;
  margin: auto;
  text-align: left;
  border-bottom: 1px solid #eee3;
  padding-bottom: 25px;
}

.input-group {
  width: 100%;
  margin-bottom: 15px;
}

.max-button {
  padding: 10px 15px;
  background-color: #333;
  border: 1px solid #333;
  border-radius: 4px;
  margin-left: 10px;
  color: #ccc;
  cursor: pointer;
}

.max-button:hover {
  background-color: #555;
}

.details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 12px;
}

.operation-limit {
  margin-bottom: 20px;
  font-size: 12px;
}

.transaction-form button {
  margin: 0 auto;
}

#stake .stake-info {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  background-color: #ffffff09;
  padding: 20px 20px;
  border-radius: 20px;
}

.btn-stake-timer.actived button {
  background-color: #ffffff09;
}

.title-stake {
  text-align: left;
  justify-content: flex-start !important;
  margin-left: 15px;
}
#stake .stake-info-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  background-color: transparent;
  align-items: center;
  margin: 0px;
}

.stake-info-label {
  display: flex;
  align-items: center;
  color: #ffffff4d;
}

.stake-info-icon {
  color: var(--primary-color);
  margin-right: 10px;
}

.stake-info-value {
  color: #ffffff;
}

#content-tabs-stake {
  position: relative;
  max-width: 95%;
  margin: 0 auto;
}

.text-support-stake i {
  font-size: 25px !important;
  color: #fff;
  display: none;
}

.content-available-stake,
.operation-limit {
  display: flex;
  align-items: center;
}
.icon-transfer {
  margin-left: 5px;
  padding: 4px 8px;
  background-color: rgba(255, 250, 250, 0.12);
  border-radius: 2px;
  cursor: pointer;
}

#stake .btn-stake-timer {
  width: 50%;
  margin: 0 auto;
}

.scroll-horizontal-dots {
  display: flex;
  justify-content: center;
}

.scroll-horizontal-dots div {
  width: 10px;
  height: 3px;
  background-color: rgba(255, 250, 250, 0.12);
  margin: 0 4px;
}

.scroll-horizontal-dots div.active {
  background-color: white;
}

.content-available-stake-item {
  display: flex;
}

#modal-operation-limit {
  background: #02020254;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-operation-limit .content-operation-limit {
  background: #000000fc;
  width: 80%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
}
@media (max-width: 600px) {
  .scroll-container {
    justify-content: flex-start;
  }

  #stake .scroll-item {
    width: 26%;
    height: 55px;
  }

  .text-support-stake i {
    display: block;
  }
}

@media (max-width: 390px) {
  .content-available-stake-item {
    flex-direction: column;
    margin-bottom: 10px;
  }
  .content-available-stake-item div {
    margin-bottom: 5px;
  }
}

@media (max-width: 360px) {
  #stake .scroll-item {
    width: 25%;
    height: 55px;
  }
}
