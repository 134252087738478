.task-list {
  align-items: center;
  color: #fff;
  width: 100%;
  border-radius: 10px;
  justify-content: space-around;
  margin: 0px auto 0px;
}

.task-header {
  text-align: left;
  margin-bottom: 20px;
}

.task-title {
  font-size: 20px;
  color: var(--text-color);
  margin: 0;
  width: 136px;
  margin: 0 auto;
  background-color: var(--background-panel);
  padding: 11px 20px;
  border-radius: 9px;
}

.task-description {
  font-size: 16px;
  margin: 10px 0 0 0;
}

.extra-task-content-input {
  width: 92%;
  display: flex;
  align-items: center;
}
.extra-task-content-input input {
  width: 92%;
}

.extra-task-items {
  margin-bottom: 20px;
}

.extra-task-list {
  margin-block: 30px;
}
.extra-task-list p {
  text-align: left;
}

.extra-task-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;

  border-radius: 5px;
}

.btn-boost-stake {
  width: 40%;
  margin: 0 auto;
  position: relative;
  z-index: 999;
  display: flex;
  justify-content: center;
}

.task-icon {
  font-size: 20px;
  margin-right: 10px;
}

.task-platform {
  flex-grow: 1;
  font-size: 14px;
}

.task-bonus {
  font-size: 14px;
  margin-right: 10px;
}

.task-footer {
  text-align: center;
}

.activate-bonus {
  font-size: 20px;
  margin-bottom: 10px;
}

.activate-button {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  width: 100px;
}

.content-icon-extra-task {
  margin-right: 20px;
}

@media (max-width: 600px) {
  .extra-task-content-input {
    width: 86%;
  }
  .extra-task-content-input input {
    width: 86%;
  }
}

@media (max-width: 500px) {
  .extra-task-content-input {
    width: 85%;
  }
  .extra-task-content-input input {
    width: 85%;
  }
}

@media (max-width: 390px) {
  .content-icon-extra-task {
    margin-right: 5px;
  }
}
